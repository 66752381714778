import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FadeInOutWithOpacity, hoverredInnercard, scaleInOut } from '../animations';
import { BiFolderPlus, BiHeart, BiSolidFolderPlus, BiSolidHeart } from 'react-icons/bi';
import { saveToCollections, saveToFavorites } from '../api';
import useUser from '../hooks/useUser';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useTemplates from '../hooks/useTemplates';

function TemplateDesignPin({ data, index }) {
  const { data: user, refetch: userRefetch } = useUser();
  const { refetch: temp_refetch } = useTemplates();
  const navigate = useNavigate();
  const [isHoverred, setIsHoverred] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

  const redirectToLogin = () => {
    toast.error("You must be logged in or register to perform this action.");
    navigate('/auth'); // Redirect to the login page
  };

  // Consolidated user check and access validation
  const checkUserAccess = (actionCallback) => {
    if (!user) {
      redirectToLogin();
      return false;
    }
    if (data.isLocked && !data.isunlocked.includes(user?.uid)) {
      setIsModalOpen(true); // Show modal for locked templates
      return false;
    }
    actionCallback();
    return true;
  };

  const addToCollection = async (e) => {
    e.stopPropagation();
    if (checkUserAccess(async () => {
      await saveToCollections(user, data);
      userRefetch();
    })) {}
  };

  const addToFavorites = async (e) => {
    e.stopPropagation();
    if (checkUserAccess(async () => {
      await saveToFavorites(user, data);
      temp_refetch();
    })) {}
  };

  const handleRouteNavigation = () => {
    if (checkUserAccess(() => {
      navigate(`/resumeDetail/${data?._id}`, { replace: true });
    })) {}
  };

  return (
    <motion.div key={data?._id} {...scaleInOut}>
      <div
        onMouseEnter={() => setIsHoverred(true)}
        onMouseLeave={() => setIsHoverred(false)}
        onClick={handleRouteNavigation}
        className={`w-full h-[500px] z-0 2xl:h-[740px] rounded-md overflow-hidden relative ${data.isLocked && !data.isunlocked.includes(user?.uid) ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        <img
          src={data?.imageURL}
          alt="Template"
          className="w-full h-[80%] object-contain bg-indigo-200 py-2 px-2"
        />

        {/* Render Locked Badge */}
        {data.isLocked && !data.isunlocked.includes(user?.uid) && (
          <div className="absolute top-2 left-2 bg-red-600 text-white text-sm px-2 py-1 rounded">
            Locked
          </div>
        )}

        {/* Overlay Text for Locked Templates */}
        {data.isLocked && !data.isunlocked.includes(user?.uid) && (
          <div className="absolute h-[80%] inset-0 flex items-center justify-center  bg-black bg-opacity-40 text-white text-lg">
            You must purchase this template
          </div>
        )}

        <AnimatePresence>
          {isHoverred && (
            <motion.div {...FadeInOutWithOpacity} className="absolute h-[80%] inset-0 bg-[rgba(0,0,0,0.4)] flex flex-col items-center justify-start px-3 py-3 z-50 cursor-pointer">
              <div className="flex flex-col items-end justify-start w-full gap-4">
                <InnerBoxCard 
                  label={user?.collections?.includes(data?._id) ? "in your collections" : "Add to collections"} 
                  Icon={user?.collections?.includes(data?._id) ? BiSolidFolderPlus : BiFolderPlus} 
                  onHandle={addToCollection} 
                />
                <InnerBoxCard 
                  label={data?.favorites?.includes(user?.uid) ? "you liked" : "like this template"} 
                  Icon={data?.favorites?.includes(user?.uid) ? BiSolidHeart : BiHeart}  
                  onHandle={addToFavorites} 
                />
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>

      {/* Modal for Premium Template */}
      <AnimatePresence>
        {isModalOpen && (
          <motion.div
            key="modal"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          >
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h3 className="text-lg font-semibold mb-4">Template Locked</h3>
              <p className="text-sm mb-4">
                To buy this template, here:{" "}
                <Link
                  to="https://buymeacoffee.com/orlici"
                  target="_blank"  // Opens the link in a new tab
                  className="text-blue-500 underline"
                >
                  Subscribe TO SUPPORT THE WEBSITE
                </Link>
              </p>
              <button
                onClick={() => setIsModalOpen(false)}
                className="bg-blue-500 text-white px-4 py-2 rounded-md"
              >
                Close
              </button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}

const InnerBoxCard = ({ label, Icon, onHandle }) => {
  const [isHoverred, setIsHoverred] = useState(false);

  return (
    <div
      onClick={onHandle}
      onMouseEnter={() => setIsHoverred(true)}
      onMouseLeave={() => setIsHoverred(false)}
      className="w-10 h-10 rounded-md bg-gray-300 flex items-center justify-center hover:shadow-md relative"
    >
      <Icon className="text-txtPrimary text-base" />
      {isHoverred && (
        <AnimatePresence>
          <motion.div
            {...hoverredInnercard}
            className="px-3 capitalize py-2 rounded-md bg-gray-200 absolute -left-44 after:w-2 after:h-2 after:bg-gray-200 after:absolute after:-right-1 after:top-[14px] after:rotate-45"
          >
            <p className="text-sm text-txtPrimary whitespace-nowrap">{label}</p>
          </motion.div>
        </AnimatePresence>
      )}
    </div>
  );
}

export default TemplateDesignPin;
