import React, { Suspense } from 'react'
import { Header, MainSpinner } from '../components'
import { HomeContainer } from '../containers'
import { Route, Routes } from 'react-router-dom'
import  CreateTemplate from "./CreateTemplate"
import  UserProfile from "./UserProfile"
import  CreateResume from "./CreateResume"
import  TemplateDesignPinDetail from "./TemplateDesignPinDetail"
import ResizerImage from './ResizerImage'
import { Helmet } from 'react-helmet-async';
import Contact from './Contact'
import PrivacyPolicy from './PrivacyPolicy'
import HowtoUse from './HowtoUse'




function HomeScreen() {
  return (

    <>
    <Helmet>
        <title>Cv-BUILDER</title>
        <meta name="description" content="resume builder app" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.cv.dz.com/" />
      </Helmet>
      <div className='w-full flex flex-col items-center justify-center'>
      
      
      <Header />
      
      <main className='w-full '>
        <Suspense fallback={<MainSpinner />} >
<Routes>
  <Route path="/" element={<HomeContainer />} />
  <Route path="/template/create" element={<CreateTemplate />} />
  <Route path="/profile/:uid" element={<UserProfile />} />
  <Route path="/resume/*" element={<CreateResume />} />
  <Route path="/resumeDetail/:templateID" element={<TemplateDesignPinDetail />} />
  <Route path="/resize-image" element={<ResizerImage />} />
  <Route path="/contact" element={<Contact />} />
  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  <Route path="/how-use" element={<HowtoUse />} />

</Routes>
        </Suspense>
      </main>
      </div>
    </>
    
  )
}

export default HomeScreen