import React from 'react';

function HowtoUse() {
  return (
    <div className="flex flex-col items-center justify-between min-h-screen bg-slate-100 py-12 px-8">
      <h1 className="text-4xl font-semibold text-center text-blue-600 mb-6">How to Use</h1>
      
      {/* Video Section */}
      <div className="flex flex-col items-center space-y-8 w-full max-w-6xl">
        
        {/* First YouTube Video Embed */}
        <div className="w-full max-w-3xl mb-8">
          <h2 className="text-2xl text-center text-gray-700 mb-4">Watch the First Tutorial</h2>
          <iframe
            width="100%"
            height="560"  // Larger video size
            src="https://www.youtube.com/embed/MwLo-UGWZk8?autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        {/* Second YouTube Video Embed */}
        <div className="w-full max-w-3xl mb-8">
          <h2 className="text-2xl text-center text-gray-700 mb-4">add new experience field</h2>
          <iframe
            width="100%"
            height="560"  // Larger video size
            src="https://www.youtube.com/embed/ONkBkNC6bmY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default HowtoUse;
