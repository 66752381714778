import React, { useEffect, useRef, useState } from "react";
import MainSpinner from "../MainSpinner";
import { useQuery } from "react-query";
import useUser from "../../hooks/useUser";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { db } from "../../config/firebase.config";
import { getTemplateDetailEditByUser } from "../../api";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";


import { LuPhone } from "react-icons/lu";
import { IoShareSocialOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";

import { MdOutlineMarkEmailRead } from "react-icons/md";

import { TemplateFour, TemplateNine, TemplateOne, TemplateThree } from "../../assets";
import {
  FaHouse,
  FaTrash,
  FaPenToSquare,
  FaPencil,
  FaPlus,
} from "react-icons/fa6";
import { BiSolidBookmarks } from "react-icons/bi";
import {
  BsFiletypePdf,
  BsFiletypePng,
  BsFiletypeJpg,
  BsFiletypeSvg,
} from "react-icons/bs";

import { AnimatePresence, motion } from "framer-motion";
import { FadeInOutWIthOpacity, opacityINOut } from "../../animations";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

import lab from "../../assets/img/lab.png";
import blood from "../../assets/img/blood.png";
import genetic from "../../assets/img/genetic.png";


const Template18 = () => {
  const { pathname } = useLocation();
  const location = useLocation();
  const navigate = useNavigate();
  const templateName = pathname?.split("/")?.slice(-1);
  const searchParams = new URLSearchParams(location.search);
  const loadedTemplateId = searchParams.get("templateId");
  // console.log(pathname, templateName, loadedTemplateId);

  const [isEdit, setIsEdit] = useState(false);
  const { data: user } = useUser();

  const resumeRef = useRef(null);

  const [imageAsset, setImageAsset] = useState({
    isImageLoading: false,
    imageURL: null,
  });

  const {
    data: resumeData,
    isLoading: resume_isLoading,
    isError: resume_isError,
    refetch: refetch_resumeData,
  } = useQuery(["templateEditedByUser", `${templateName}-${user?.uid}`], () =>
    getTemplateDetailEditByUser(user?.uid, `${templateName}-${user?.uid}`)
  );

  const [formData, setFormData] = useState({
    fullname: "jhon doe",
    professionalTitle: "agent administratif",
    personalDescription: `rem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a gall`,
    refererName: "pharm constantine",
    refererRole: "Director | Company Name",
    mobile: "+91 0000-0000",
    email: "urname@gmail.com",
    website: "urwebsite.com",
    address: "your street address, ss, street, city/zip code - 1234",
  });

  const [experiences, setExperiences] = useState([
    {
      year: "2012 - 2014",
      title: "Job Position Here",
      companyAndLocation: "Company Name / Location here",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis voluptatibus minima tenetur nostrum quo aliquam dolorum incidunt.",
    },
    {
      year: "2012 - 2014",
      title: "Job Position Here",
      companyAndLocation: "Company Name / Location here",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis voluptatibus minima tenetur nostrum quo aliquam dolorum incidunt.",
    },
    {
      year: "2012 - 2014",
      title: "Job Position Here",
      companyAndLocation: "Company Name / Location here",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis voluptatibus minima tenetur nostrum quo aliquam dolorum incidunt.",
    },
  ]);

  const [skills, setSkills] = useState([
    {
      title: "français",
      percentage: "75",
    },
    {
      title: "anglais",
      percentage: "75",
    },
    {
      title: "espagol",
      percentage: "75",
    },
    {
      title: "deutsch",
      percentage: "75",
    },
    {
      title: "arabic",
      percentage: "75",
    },
  ]);

  const [education, setEducation] = useState([
    {
      major: "master 2 managements",
      university: "université mentouri constantine / annee 2007-2012",
    },
  ]);
  const [Experience, setExperience] = useState([
    {
      title: "experience",
     
    },
  ]);
  const [aboutMe, setaboutMe] = useState([
    {
      title: "about me",
     
    },
  ]);
  const [educationTitle, seteducationTitle] = useState([
    {
      title: "education",
     
    },
  ]);

  const [skillTitle, setskillTitle] = useState([
    {
      title: "skills",
     
    },
  ]);
  
  

  

  useEffect(() => {
    if (resumeData?.formData) {
      setFormData({ ...resumeData?.formData });
    }
    if (resumeData?.experiences) {
      setExperiences(resumeData?.experiences);
    }
    if (resumeData?.skills) {
      setSkills(resumeData?.skills);
    }
    if (resumeData?.education) {
      setEducation(resumeData?.education);
    }
    if (resumeData?.Experience) {
      setExperience(resumeData?.Experience);
    }
    if (resumeData?.aboutMe) {
      setaboutMe(resumeData?.aboutMe);
    }
    if (resumeData?.educationTitle) {
      seteducationTitle(resumeData?.educationTitle);
    }
    if (resumeData?.skillTitle) {
      setskillTitle(resumeData?.skillTitle);
    }
    
      
    if (resumeData?.userProfilePic) {
      setImageAsset((prevAsset) => ({
        ...prevAsset,
        imageURL: resumeData?.userProfilePic,
      }));
    }
  }, [resumeData]);

  const handleaboutChange = (index, e) => {
    const { name, value } = e.target;
    const updatedaboutMe = [...aboutMe];
    updatedaboutMe[index][name] = value;
    setaboutMe(updatedaboutMe);
  };
  const handleeducationTitleChange = (index, e) => {
    const { name, value } = e.target;
    const updatededucationTitle = [...educationTitle];
    updatededucationTitle[index][name] = value;
    seteducationTitle(updatededucationTitle);
  };
  

  const handleskillTitleChange = (index, e) => {
    const { name, value } = e.target;
    const updatedskillTitle = [...skillTitle];
    updatedskillTitle[index][name] = value;
    setskillTitle(updatedskillTitle);
  };
  
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditable = () => {
    setIsEdit(!isEdit);
    var inputs = document.querySelectorAll("input");
    var textarea = document.querySelectorAll("textarea");

    for (var i = 0; i < inputs.length; i++) {
      inputs[i].readOnly = !inputs[i].readOnly;
    }

    for (var i = 0; i < textarea.length; i++) {
      textarea[i].readOnly = !textarea[i].readOnly;
    }
  };

  // image upload to the cloud
  const handleFileSelect = async (event) => {
    setImageAsset((prevAsset) => ({ ...prevAsset, isImageLoading: true }));
    // console.log(event.target.files[0]);
    const file = event.target.files[0];
    if (file && isAllowed(file)) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const dataURL = event.target.result;
        console.log("Data URL:", dataURL);

        // You can now use the dataURL as needed, e.g., to display an image.
        setImageAsset((prevAsset) => ({
          ...prevAsset,
          imageURL: dataURL,
        }));
      };

      // Read the file as a Data URL
      reader.readAsDataURL(file);
    } else {
      toast.error("Invalid File Format");
    }
  };

  const isAllowed = (file) => {
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    return allowedTypes.includes(file.type);
  };

  // delete an image
  const deleteImageObject = () => {
    setImageAsset((prevAsset) => ({
      ...prevAsset,
      imageURL: null,
    }));
  };

  // uploader finshed

  const handleExpChange = (index, e) => {
    const { name, value } = e.target;
    // Create a copy of the workExperiences array
    const updatedExperiences = [...experiences];
    // Update the specific field for the experience at the given index
    updatedExperiences[index][name] = value;
    // Update the state with the modified array
    setExperiences(updatedExperiences);
  };

  const removeExperience = (index) => {
    // Create a copy of the workExperiences array and remove the experience at the given index
    const updatedExperiences = [...experiences];
    updatedExperiences.splice(index, 1);
    // Update the state with the modified array
    setExperiences(updatedExperiences);
  };

  const addExperience = () => {
    // Create a copy of the workExperiences array and add a new experience
    const updatedExperiences = [
      ...experiences,
      {
        year: "2012 - 2014",
        title: "Job Position Here",
        companyAndLocation: "Company Name / Location here",
        description:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Corporis voluptatibus minima tenetur nostrum quo aliquam dolorum incidunt.",
      },
    ];
    // Update the state with the modified array
    setExperiences(updatedExperiences);
  };

  const handleSkillsChange = (index, e) => {
    const { name, value } = e.target;
    const updatedSkills = [...skills];
    updatedSkills[index][name] = value;
    setSkills(updatedSkills);
  };

  const removeSkill = (index) => {
    const updatedSkills = [...skills];
    updatedSkills.splice(index, 1);
    setSkills(updatedSkills);
  };

  const addSkill = () => {
    const updatedSkills = [
      ...skills,
      {
        title: "skill1",
        percentage: "75",
      },
    ];
    setSkills(updatedSkills);
  };

  const handleEducationChange = (index, e) => {
    const { name, value } = e.target;
    const updatedEdu = [...education];
    updatedEdu[index][name] = value;
    setEducation(updatedEdu);
  };

  const handleExperienceChange = (index, e) => {
    const { name, value } = e.target;
    const updatedExperience = [...Experience];
    updatedExperience[index][name] = value;
    setExperience(updatedExperience);
  };

  const removeEducation = (index) => {
    const updatedEdu = [...education];
    updatedEdu.splice(index, 1);
    setEducation(updatedEdu);
  };

  const addEducation = () => {
    const updatedEdu = [
      ...education,
      {
        major: "ENTER YOUR MAJOR",
        university: "Name of your university / college 2005-2009",
      },
    ];
    setEducation(updatedEdu);
  };

  const saveFormData = async () => {
    const timeStamp = serverTimestamp();
    const resume_id = `${templateName}-${user?.uid}`;
    
    // Check if an image file needs to be uploaded
    const imageFile = await getImage(); // Assuming getImage() provides the image file object
    let uploadProgress = 0; // Track upload progress
    
    if (imageFile) {
      // Upload the image to Firebase Storage
      const storage = getStorage();
      const storageRef = ref(storage, `user_resumes/${resume_id}`);
      
      const uploadTask = uploadBytesResumable(storageRef, imageFile);
  
      // Track the upload progress
      uploadTask.on('state_changed',
        (snapshot) => {
          // Get the upload progress percentage
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          uploadProgress = progress;  // Update the progress value
          // Optionally, you can display the progress using a toast, a progress bar, or other UI elements.
          toast.info(`saving image...`);
        },
        (error) => {
          toast.error(`Error uploading image: ${error.message}`);
        },
        async () => {
          // Image uploaded successfully, get the download URL
          const imageURL = await getDownloadURL(uploadTask.snapshot.ref);
  
          // Create the document for Firestore
          const _doc = {
            _id: loadedTemplateId,
            resume_id,
            formData,
            education,
            Experience,
            skillTitle,
            educationTitle,
            aboutMe,
            experiences,
            skills,
            timeStamp,
            userProfilePic: imageAsset.imageURL, // Assuming this is already a valid URL
            imageURL,  // Save only the URL
          };
  
          // Save the data to Firestore
          setDoc(doc(db, "users", user?.uid, "resumes", resume_id), _doc)
            .then(() => {
              toast.success(`Data Saved!`);
              refetch_resumeData();
            })
            .catch((err) => {
              toast.error(`Error saving data: ${err.message}`);
            });
        }
      );
    } else {
      // Handle case where no image file was selected (fallback logic)
      const _doc = {
        _id: loadedTemplateId,
        resume_id,
        formData,
        education,
        Experience,
        skillTitle,
        aboutMe,
        educationTitle,
        experiences,
        skills,
        timeStamp,
        userProfilePic: imageAsset.imageURL, // Assuming this is already a valid URL
        imageURL: '',  // No image URL if no image file was selected
      };
  
      // Save the data to Firestore directly without image upload
      setDoc(doc(db, "users", user?.uid, "resumes", resume_id), _doc)
        .then(() => {
          toast.success(`Data Saved!`);
          refetch_resumeData();
        })
        .catch((err) => {
          toast.error(`Error saving data: ${err.message}`);
        });
    }
  };
  

  const getImage = async () => {
    const element = resumeRef.current;
    element.onload = async () => {
      // Call the image capture code here
    };
    element.onerror = (error) => {
      console.error("Image loading error:", error);
    };
    if (!element) {
      console.error("Unable to capture content. The DOM element is null.");
      return;
    }
    try {
      const dataUrl = await htmlToImage.toJpeg(element);
      console.log(dataUrl);
      return dataUrl;
    } catch (error) {
      console.error("Oops, something went wrong!", error.message);
      return null; // Return a default value or handle the error appropriately
    }
  };

  const generatePDF = async () => {
    const element = resumeRef.current;
    if (!element) {
      toast.info("Unable to capture the content");
      return;
    }
  
    // Display progress indicator
    const progressBar = document.createElement("div");
    progressBar.style.position = "fixed";
    progressBar.style.top = "50%";
    progressBar.style.left = "50%";
    progressBar.style.transform = "translate(-50%, -50%)";
    progressBar.style.zIndex = "9999";
    progressBar.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    progressBar.style.color = "#fff";
    progressBar.style.padding = "20px";
    progressBar.style.borderRadius = "10px";
    progressBar.style.textAlign = "center";
    progressBar.style.fontSize = "16px";
    progressBar.innerText = "Preparing PDF... 0%";
    document.body.appendChild(progressBar);
  
    try {
      // Step 1: Capture element as PNG
      progressBar.innerText = "Capturing content... 25%";
      const dataUrl = await htmlToImage.toPng(element, { 
        quality: 1, // Best quality
        pixelRatio: 3, // HD quality
      });
  
      // Step 2: Prepare PDF dimensions and format
      progressBar.innerText = "Generating PDF... 50%";
      const a4Width = 210; // A4 width in mm
      const a4Height = 297; // A4 height in mm
      const pdf = new jsPDF({
        orientation: "p",
        unit: "mm",
        format: [a4Width, a4Height],
      });
  
      // Step 3: Add image to PDF
      progressBar.innerText = "Finalizing PDF... 75%";
      const aspectRatio = a4Width / a4Height;
      const imgWidth = a4Width;
      const imgHeight = imgWidth / aspectRatio;
      const verticalMargin = (a4Height - imgHeight) / 2;
  
      pdf.addImage(dataUrl, "PNG", 0, verticalMargin, imgWidth, imgHeight, undefined, 'FAST');
  
      // Step 4: Save PDF
      progressBar.innerText = "Saving PDF... 100%";
      pdf.save("resume.pdf");
  
      // Remove progress bar after a short delay
      setTimeout(() => {
        document.body.removeChild(progressBar);
      }, 1000);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      // Remove progress bar if an error occurs
      const progressBar = document.querySelector('div[style*="Preparing PDF"]');
      if (progressBar) {
        document.body.removeChild(progressBar);
      }
    }
  };
  

  const generateImage = async () => {
    const element = resumeRef.current;
    if (!element) {
      toast.info("Unable to capture the content");
      return;
    }
  
    // Display progress indicator
    const progressBar = document.createElement("div");
    progressBar.style.position = "fixed";
    progressBar.style.top = "50%";
    progressBar.style.left = "50%";
    progressBar.style.transform = "translate(-50%, -50%)";
    progressBar.style.zIndex = "9999";
    progressBar.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
    progressBar.style.color = "#fff";
    progressBar.style.padding = "20px";
    progressBar.style.borderRadius = "10px";
    progressBar.style.textAlign = "center";
    progressBar.style.fontSize = "16px";
    progressBar.innerText = "Preparing Image... 0%";
    document.body.appendChild(progressBar);
  
    try {
      // Step 1: Set dimensions for A4 size (in pixels at 300 DPI)
      progressBar.innerText = "Setting up canvas... 25%";
      const a4Width = 2480; // 210mm * 300 DPI
      const a4Height = 3508; // 297mm * 300 DPI
  
      // Step 2: Capture content as JPEG
      progressBar.innerText = "Capturing content... 50%";
      const dataUrl = await htmlToImage.toJpeg(element, {
        quality: 1, // Best quality
        pixelRatio: 3, // Adjust as needed for HD quality
        canvasWidth: a4Width,
        canvasHeight: a4Height,
      });
  
      // Step 3: Finalizing image and preparing download link
      progressBar.innerText = "Finalizing image... 75%";
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "resume-a4.jpg";
      link.click();
  
      // Step 4: Complete process
      progressBar.innerText = "Image ready... 100%";
      setTimeout(() => {
        document.body.removeChild(progressBar);
      }, 1000);
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      // Remove progress bar if an error occurs
      const progressBar = document.querySelector('div[style*="Preparing Image"]');
      if (progressBar) {
        document.body.removeChild(progressBar);
      }
    }
  };
  
  
  
  const generatePng = async () => {
    const element = resumeRef.current;
    if (!element) {
      toast.info("Unable to capture the content");
      return;
    }
  
    try {
      // Set dimensions for A4 size (in pixels at 300 DPI)
      const a4Width = 2480; // 210mm * 300 DPI
      const a4Height = 3508; // 297mm * 300 DPI
  
      // Display progress indicator
      const progressBar = document.createElement("div");
      progressBar.style.position = "fixed";
      progressBar.style.top = "50%";
      progressBar.style.left = "50%";
      progressBar.style.transform = "translate(-50%, -50%)";
      progressBar.style.zIndex = "9999";
      progressBar.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
      progressBar.style.color = "#fff";
      progressBar.style.padding = "20px";
      progressBar.style.borderRadius = "10px";
      progressBar.style.textAlign = "center";
      progressBar.style.fontSize = "16px";
      progressBar.innerText = "Preparing PNG... 0%";
      document.body.appendChild(progressBar);
  
      // Use html-to-image library to capture the content as a PNG
      const dataUrl = await htmlToImage.toPng(element, {
        pixelRatio: 3, // Adjust for HD quality
        canvasWidth: a4Width,
        canvasHeight: a4Height,
        onClone: () => {
          progressBar.innerText = "Preparing PNG... 25%";
        },
        onRender: () => {
          progressBar.innerText = "Rendering PNG... 75%";
        },
      });
  
      // Update progress to 100%
      progressBar.innerText = "Finalizing PNG... 100%";
  
      // Remove progress bar after a short delay
      setTimeout(() => {
        document.body.removeChild(progressBar);
      }, 1000);
  
      // Create a download link for the generated image
      const link = document.createElement("a");
      link.href = dataUrl;
      link.download = "resume-a4.png";
      link.click();
    } catch (err) {
      toast.error(`Error: ${err.message}`);
      // Remove progress bar if an error occurs
      const progressBar = document.querySelector('div[style*="Preparing PNG"]');
      if (progressBar) {
        document.body.removeChild(progressBar);
      }
    }
  };
  

  if (resume_isLoading) return <MainSpinner />;

  if (resume_isError) {
    return (
      <div className="w-full h-[60vh] flex flex-col items-center justify-center">
        <p className="text-lg text-txtPrimary font-semibold">
          Error While fetching the data
        </p>
      </div>
    );
  }
  return (
    <div className="w-full flex flex-col items-center justify-start  gap-4">
      {/* bread crump */}
      <div className="w-full flex items-center gap-2 px-4">
        <Link
          to={"/"}
          className="flex items-center justify-center gap-2 text-txtPrimary"
        >
          <FaHouse />
          Home
        </Link>
        <p
          className="text-txtPrimary cursor-pointer"
          onClick={() => navigate(-1)}
        >
          / Template2 /
        </p>
        <p>Edit</p>
      </div>

      <div className="w-full lg:w-[1200px]  grid grid-cols-1 lg:grid-cols-12 px-6 lg:px-32">
        {/* template design */}
        <div className="col-span-12  px-4 py-6 bg">
          <div className="flex items-center justify-end w-full gap-12 mb-4">
            <div
              className="flex items-center justify-center gap-1 px-3 py-1 rounded-md bg-gray-200 cursor-pointer"
              onClick={toggleEditable}
            >
              {isEdit ? (
                <FaPenToSquare className="text-sm text-txtPrimary" />
              ) : (
                <FaPencil className="text-sm text-txtPrimary" />
              )}
              <p className="text-sm text-txtPrimary">Edit</p>
            </div>

            <div
              className="flex items-center justify-center gap-1 px-3 py-1 rounded-md bg-gray-200 cursor-pointer"
              onClick={saveFormData}
            >
              <BiSolidBookmarks className="text-sm text-txtPrimary" />
              <p className="text-sm text-txtPrimary">Save</p>
            </div>

            <div className=" flex items-center justify-center gap-2">
              <p className="text-sm text-txtPrimary">Download : </p>
              <BsFiletypePdf
                className="text-2xl text-txtPrimary cursor-pointer"
                onClick={generatePDF}
              />
              <BsFiletypePng
                onClick={generatePng}
                className="text-2xl text-txtPrimary cursor-pointer"
              />
              <BsFiletypeJpg
                className="text-2xl text-txtPrimary cursor-pointer"
                onClick={generateImage}
              />
             
            </div>
          </div>
          <div  
          
          className=" bg-[url('./assets/img/bg-sketch.jpg')] bg-cover bg-center   w-full h-auto border-4 bg-gray-100 border-gray-300 grid grid-cols-12" ref={resumeRef}>
            <div className="col-span-4 mx-6 bg-transparent  z-30 flex flex-col items-center justify-start">
              <div className="w-full h-80 bg-transparent flex items-center justify-center">
                {!imageAsset.imageURL ? (
                  <React.Fragment>
                    <label className=" w-full bg-transparent  cursor-pointer h-full">
                      <div className="w-full flex flex-col items-center justify-center h-full">
                        <div className="w-full flex flex-col justify-center items-center cursor-pointer">
                          <img
                            src={TemplateNine}
                            className="w-52 h-52 border-8 border-white rounded-full  object-cover"
                            alt=""
                          />
                        </div>
                      </div>

                      {isEdit && (
                        <input
                          type="file"
                          className="w-0 h-0"
                          accept=".jpeg,.jpg,.png"
                          onChange={handleFileSelect}
                        />
                      )}
                    </label>
                  </React.Fragment>
                ) : (
                  <div className="relative w-full flex flex-col bg-transparent h-full justify-center items-center cursor-pointer">
                    <img
                      src={imageAsset.imageURL}
                      alt="uploaded image"
                      className="w-52 h-52 object-cover border-8 border-white rounded-full"
                      loading="lazy"
                    />

                    {isEdit && (
                      <div
                        className="absolute top-4 right-4 w-8 h-8 rounded-md flex items-center justify-center bg-red-500 cursor-pointer"
                        onClick={deleteImageObject}
                      >
                        <FaTrash className="text-sm text-white" />
                      </div>
                    )}
                  </div>
                )}

                
              </div>

              <div className="w-full flex flex-col items-center justify-start pl-8  gap-6">
                  {/* title */}
              <div
               style={{
               
                }} className="  w-64   ">
                <div className="flex items-center justify-start  ">
              
                  <input
                    type="text"
                    readOnly="true"
                    name="fullname"
                    value={formData.fullname}
                    onChange={handleChange}
                    className={`bg-transparent outline-none border-none text-3xl font-sans uppercase tracking-wider text-blue-900 font-extrabold ${
                      isEdit && "text-gray-400 w-full"
                    }`}
                  />
                    
                </div>

                <input
                  value={formData.professionalTitle}
                  onChange={handleChange}
                  name="professionalTitle"
                  type="text"
                  readOnly="true"
                  className={` w-auto  bg-white text-center font-bold p-1 my-1 bg-transparent rounded-md outline-none border-none text-md  tracking-widest capitalize text-gray-700 }`}
                />
                 <div className="w-full mt-3">
                 <AnimatePresence>
                            {aboutMe &&
                              aboutMe?.map((about, i) => (
                                <motion.div
                                  key={i}
                                  {...opacityINOut(i)}
                                  className="w-full mt-4 ml-1 relative"
                                >
                                  <input
                                    type="text"
                                    readOnly="true"
                                    name="title"
                                    value={about.title}
                                    onChange={(e) => handleaboutChange(i, e)}
                                    className={`bg-transparent outline-none border-none text-lg font-semibold ml-2  uppercase  text-blue-900 tracking-wider ${
                                      isEdit && "text-blue-700 tracking-wider"
                                    }`}
                                    />
                                </motion.div>
                              ))}
                            </AnimatePresence>

                  <div className="w-[10%] h-1 ml-3 bg-blue-500 my-2"></div>
                  <textarea
                    readOnly="true"
                    className={`text-base p-1 bg-transparent ml-2  text-txtPrimary tracking-wider w-full  outline-none border-none ${
                      isEdit ? "bg-green-200 " : "bg-transparent"
                    }`}
                    name="personalDescription"
                    value={formData.personalDescription}
                    onChange={handleChange}
                    rows="4"
                    style={{
                      minHeight: "100px",
                      width: "100%",
                      height: "200px",
                      resize: "none",
                    }}
                  />
                </div>
              </div>
                <div className="w-full">
                
                <AnimatePresence>
                            {educationTitle &&
                              educationTitle?.map((eduTitle, i) => (
                                <motion.div
                                  key={i}
                                  {...opacityINOut(i)}
                                  className="w-full mt-3 -ml-5 relative"
                                >
                                  <input
                                    type="text"
                                    readOnly="true"
                                    name="title"
                                    value={eduTitle.title}
                                    onChange={(e) => handleeducationTitleChange(i, e)}
                                    className={`bg-transparent outline-none border-none text-lg font-semibold ml-2  uppercase  text-blue-900 tracking-wider ${
                                      isEdit && "text-blue-700 tracking-wider"
                                    }`}
                                    />
                                </motion.div>
                              ))}
                            </AnimatePresence>
                  <div className="w-[20%]  h-[3px] bg-blue-500 -ml-3"></div>
                 


                  <AnimatePresence>
                    {education &&
                      education?.map((edu, i) => (
                        <motion.div
                          key={i}
                          {...opacityINOut(i)}
                          className="w-full -ml-3  relative"
                        >
                          <input
                            type="text"
                            readOnly="true"
                            name="major"
                            value={edu.major}
                            onChange={(e) => handleEducationChange(i, e)}
                            className={`bg-transparent outline-none border-none mt-2 text-sm font-semibold uppercase  text-gray-700  ${
                              isEdit && "text-blue-900 w-full"
                            }`}
                          
                          />

                          <textarea
                            readOnly="true"
                            className={`text-xs text-gray-700 mt-2  w-full  outline-none border-none bg-transparent`}
                            name="university"
                            value={edu.university}
                            onChange={(e) => handleEducationChange(i, e)}
                            rows="2"
                            style={{
                              maxHeight: "auto",
                              minHeight: "5rem",
                              resize: "none",
                            }}
                          />
                          <AnimatePresence>
                            {isEdit && (
                              <motion.div
                                {...FadeInOutWIthOpacity}
                                onClick={() => removeEducation(i)}
                                className="cursor-pointer absolute -right-5 top-2"
                              >
                                <FaTrash className="text-sm text-gray-400" />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </motion.div>
                      ))}
                  </AnimatePresence>
                </div>

                <AnimatePresence>
                  {isEdit && (
                    <motion.div
                      {...FadeInOutWIthOpacity}
                      onClick={addEducation}
                      className="cursor-pointer"
                    >
                      <FaPlus className="text-base text-gray-400" />
                    </motion.div>
                  )}
                </AnimatePresence>

                {/* reference */}
                <div className="w-full">
                  <p className="uppercase text-lg -ml-3 font-semibold text-blue-900">
                    Reference
                  </p>
                  <div className="w-[20%] h-[3px] bg-blue-500 mt-2 -ml-3"></div>
                  <div className="w-full -ml-2 mt-3">
                    <input
                      value={formData.refererName}
                      onChange={handleChange}
                      name="refererName"
                      type="text"
                      readOnly="true"
                      className={`bg-transparent outline-none border-none text-base tracking-widest capitalize text-gray-700 w-full ${
                        isEdit && "bg-[#1c1c1c]"
                      }`}
                    />

                    <input
                      value={formData.refererRole}
                      onChange={handleChange}
                      name="refererRole"
                      type="text"
                      readOnly="true"
                      className={`bg-transparent outline-none border-none text-sm capitalize text-gray-700 w-full ${
                        isEdit && "bg-[#1c1c1c]"
                      }`}
                    />
                  </div>
                </div>
              </div>

              <div className="w-full flex flex-col py-2 items-start justify-start mt-6 gap-6">
                <div className="w-full grid grid-cols-12">
                  <div className="col-span-3 w-full h-6  flex justify-center items-center ">
                    <LuPhone  />
                    </div>
                  <div className="col-span-9">
                    <div className="w-full h-6  px-3 flex items-center">
                      <p className="text-sm font-semibold text-blue-900">
                        Phone
                      </p>
                    </div>
                    <input
                      value={formData.mobile}
                      onChange={handleChange}
                      name="mobile"
                      type="text"
                      readOnly="true"
                      className={`bg-transparent outline-none border-none text-sm px-3 mt-2 text-gray-900 w-full ${
                        isEdit && "bg-[#1c1c1c]"
                      }`}
                    />
                  </div>
                </div>

                {/* email */}
                <div className="w-full grid grid-cols-12">
                <div className="col-span-3 w-full h-6  flex justify-center items-center ">
                <MdOutlineMarkEmailRead />
                    </div>
                  <div className="col-span-9">
                    <div className="w-full h-6  px-3 flex items-center">
                      <p className="text-sm font-semibold text-blue-900">
                        Email
                      </p>
                    </div>
                    <input
                      value={formData.email}
                      onChange={handleChange}
                      name="email"
                      type="text"
                      readOnly="true"
                      className={`bg-transparent outline-none border-none text-sm px-3 mt-2 text-gray-700 w-full ${
                        isEdit && "bg-[#1c1c1c]"
                      }`}
                    />
                  </div>
                </div>

             

                {/* address */}
                <div className="w-full grid grid-cols-12">
                <div className="col-span-3 w-full h-6  flex justify-center items-center ">
                <IoHomeOutline />
                    </div>
                  <div className="col-span-9">
                    <div className="w-full h-6  px-3 flex items-center">
                      <p className="text-sm font-semibold text-blue-900">
                        Address
                      </p>
                    </div>

                    <textarea
                      readOnly="true"
                      className={`text-sm  mt-3 px-2  w-full text-gray-700  outline-none border-none  bg-transparent`}
                      name="address"
                      value={formData.address}
                      onChange={handleChange}
                      rows="2"
                      style={{
                        maxHeight: "auto",
                        minHeight: "60px",
                        resize: "none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div   className="col-span-8 mt-80 flex  pl-4 border-l-4 ml-6 border-gray-300 flex-col items-center justify-start   bg-gradient-to-r from-white-200 to-white-200">
             
            

              {/* about me */}
              <div className="w-full bg-transparent px-8 py-6 flex flex-col items-start justify-start gap-6">
               

                {/* experience */}
                <div className="w-full">
               <div className="flex justify-start items-center space-x-3">
            

               <AnimatePresence>
                            {Experience &&
                              Experience?.map((Exp, i) => (
                                <motion.div
                                  key={i}
                                  {...opacityINOut(i)}
                                  className="w-full mt-3 relative"
                                >
                                  <input
                                    type="text"
                                    readOnly="true"
                                    name="title"
                                    value={Exp.title}
                                    onChange={(e) => handleExperienceChange(i, e)}
                                    className={`bg-transparent outline-none border-none text-xl  uppercase  text-blue-700 tracking-wider ${
                                      isEdit && "text-blue-700 tracking-wider"
                                    }`}
                                    />
                                </motion.div>
                              ))}
                            </AnimatePresence>

                  </div>
                  
                  <div className="w-[10%] h-1 bg-blue-500 my-3"></div>
                  <div className="w-full flex flex-col items-center justify-start gap-4">
                    <AnimatePresence>
                      {experiences &&
                        experiences?.map((exp, i) => (
                          <motion.div
                            {...opacityINOut(i)}
                            className="w-full grid grid-cols-12"
                            key={i}
                          >
                            <div className="col-span-4">
                              <input
                                value={exp.year}
                                onChange={(e) => handleExpChange(i, e)}
                                name="year"
                                type="text"
                                readOnly="true"
                                className={` outline-none border-none text-base tracking-eide uppercase text-txtDark w-full ${
                                  isEdit ? "bg-gray-200" : "bg-transparent"
                                }`}
                              />
                            </div>
                            <div className="col-span-8 relative">
                              <AnimatePresence>
                                {isEdit && (
                                  <motion.div
                                    {...FadeInOutWIthOpacity}
                                    onClick={() => removeExperience(i)}
                                    className="cursor-pointer absolute right-0 top-2"
                                  >
                                    <FaTrash className="text-base text-txtPrimary" />
                                  </motion.div>
                                )}
                              </AnimatePresence>
                              <input
                                value={exp.title}
                                onChange={(e) => handleExpChange(i, e)}
                                name="title"
                                type="text"
                                readOnly="true"
                                className={` outline-none border-none font-sans text-lg tracking-wide capitalize text-txtDark w-full ${
                                  isEdit ? "bg-gray-200" : "bg-transparent"
                                }`}
                              />

                              <input
                                value={exp.companyAndLocation}
                                onChange={(e) => handleExpChange(i, e)}
                                name="companyAndLocation"
                                type="text"
                                readOnly="true"
                                className={` outline-none border-none text-sm tracking-wide capitalize text-txtPrimary w-full ${
                                  isEdit ? "bg-gray-200" : "bg-transparent"
                                }`}
                              />

                              <textarea
                                readOnly="true"
                                className={`text-sm mt-4  text-txtPrimary tracking-wider w-[100%] h-[120px]  outline-none border-none ${
                                  isEdit ? "bg-gray-200" : "bg-transparent"
                                }`}
                                name="description"
                                value={exp.description}
                                onChange={(e) => handleExpChange(i, e)}
                                rows="3"
                                style={{
                                  maxHeight: "auto",
                                  minHeight: "60px",
                                  resize: "none",
                                }}
                              />
                            </div>
                          </motion.div>
                        ))}
                    </AnimatePresence>
                    <AnimatePresence>
                      {isEdit && (
                        <motion.div
                          {...FadeInOutWIthOpacity}
                          onClick={addExperience}
                          className="cursor-pointer"
                        >
                          <FaPlus className="text-base text-txtPrimary" />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>

                {/* skills */}
                <div className="w-full mt-1 ">
                <div className="flex justify-start items-center space-x-3">
                
                <AnimatePresence>
                            {skillTitle &&
                              skillTitle?.map((skillTIT, i) => (
                                <motion.div
                                  key={i}
                                  {...opacityINOut(i)}
                                  className="w-full mt-3 relative"
                                >
                                  <input
                                    type="text"
                                    readOnly="true"
                                    name="title"
                                    value={skillTIT.title}
                                    onChange={(e) => handleskillTitleChange(i, e)}
                                    className={`bg-transparent outline-none border-none text-xl  uppercase  text-blue-700 tracking-wider ${
                                      isEdit && "text-blue-700 tracking-wider"
                                    }`}
                                    />
                                </motion.div>
                              ))}
                            </AnimatePresence>
                </div>
                  <div className="w-[10%] h-1 bg-blue-500 my-3"></div>
                  <div className="w-full flex flex-wrap items-center justify-start gap-4">
                    <AnimatePresence>
                      {skills &&
                        skills?.map((skill, i) => (
                          <motion.div
                            key={i}
                            {...opacityINOut(i)}
                            className="flex-1"
                            style={{ minWidth: 180 }}
                          >
                            <div className="w-full flex items-center justify-between">
                              <div className="flex items-center justify-center">
                                <input
                                  value={skill.title}
                                  onChange={(e) => handleSkillsChange(i, e)}
                                  name="title"
                                  type="text"
                                  readOnly="true"
                                  className={` outline-none border-none text-base tracking-wide capitalize font-semibold text-txtPrimary w-full ${
                                    isEdit ? "bg-gray-200" : "bg-transparent"
                                  }`}
                                />

                                <AnimatePresence>
                                  {isEdit && (
                                    <motion.input
                                      {...FadeInOutWIthOpacity}
                                      value={skill.percentage}
                                      onChange={(e) => handleSkillsChange(i, e)}
                                      name="percentage"
                                      type="text"
                                      className={` outline-none border-none text-base tracking-wide capitalize font-semibold text-txtPrimary w-full ${
                                        isEdit
                                          ? "bg-gray-200"
                                          : "bg-transparent"
                                      }`}
                                    />
                                  )}
                                </AnimatePresence>
                              </div>

                              <AnimatePresence>
                                {isEdit && (
                                  <motion.div
                                    {...FadeInOutWIthOpacity}
                                    onClick={() => removeSkill(i)}
                                    className="cursor-pointer "
                                  >
                                    <FaTrash className="text-base text-txtPrimary" />
                                  </motion.div>
                                )}
                              </AnimatePresence>
                            </div>
                            <div className="relative mt-2 w-full h-1 rounded-md bg-gray-400">
                              <div
                                className="h-full rounded-md bg-gray-600"
                                style={{
                                  width: `${skill.percentage}%`,
                                  transition: "width 0.3s ease",
                                }}
                              ></div>
                            </div>
                          </motion.div>
                        ))}
                    </AnimatePresence>
                  </div>
                  <AnimatePresence>
                    {isEdit && (
                      <div className="w-full  flex items-center justify-center py-4">
                        <motion.div
                          {...FadeInOutWIthOpacity}
                          onClick={addSkill}
                          className="cursor-pointer"
                        >
                          <FaPlus className="text-base text-txtPrimary" />
                        </motion.div>
                      </div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template18;



